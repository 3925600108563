import React, {useState, useEffect} from 'react';
import {BrowserRouter as Router, Routes, Route, Navigate, useLocation} from 'react-router-dom';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import Navbar from './components/Navbar';
import SideMenu from './components/SideMenu';
import PublicationDetails from "./components/PublicationDetails";
import PostDetails from "./components/PostDetails";
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import RegeneratedDashboard from "./components/RegeneratedDashboard";
import InstaStoryForm from "./components/InstaStoryForm";
import GenerationEntryPage from "./components/GenerationEntryPage";
import GenerationChoosePage from "./components/GenerationChoosePage";
import DeletedDashboard from "./components/DeletedDashboard";
import NotificationDashboard from "./components/NotificationDashboard";
import RegeneratedDetails from "./components/RegeneratedDetails";
import LatestDashboard from "./components/LatestDashboard";
import TikTokForm from "./components/TikTokForm";
import PostForm from "./components/PostForm";
import GoogleLogin from "./components/GoogleLogin";


function App() {
    const [authData, setAuthData] = useState({
        token: sessionStorage.getItem('authToken'),
        userType: sessionStorage.getItem('userType'),
    });

    useEffect(() => {
        if (authData.token) {
            sessionStorage.setItem('authToken', authData.token);
            sessionStorage.setItem('userType', authData.userType);
        } else {
            sessionStorage.removeItem('authToken');
            sessionStorage.removeItem('userType');
        }
    }, [authData]);

    return (
        <Router>
            <MainContent authData={authData} setAuthData={setAuthData}/>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </Router>
    );
}

function MainContent({authData, setAuthData}) {
    const location = useLocation();
    const isLoginPage = location.pathname === "/login";
    const isAuthenticated = !!authData.token;

    const [isMenuOpen, setIsMenuOpen] = useState(window.innerWidth >= 1000);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth >= 1000) {
                setIsMenuOpen(true);
            } else {
                setIsMenuOpen(false);
            }
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);


    return (
        <>
            {isAuthenticated && !isLoginPage && (
                <>
                    <Navbar userType={authData.userType} toggleMenu={toggleMenu}/>
                    <SideMenu isOpen={isMenuOpen} toggleMenu={toggleMenu}/>
                </>
            )}

            <Routes>
                <Route
                    path="/"
                    element={
                        isAuthenticated ? (
                            <Dashboard authData={authData} setAuthData={setAuthData}/>
                        ) : (
                            <Navigate to="/login" replace/>
                        )
                    }
                />
                <Route
                    path="/regenerated"
                    element={
                        isAuthenticated ? (
                            <RegeneratedDashboard authData={authData} setAuthData={setAuthData}/>
                        ) : (
                            <Navigate to="/login" replace/>
                        )
                    }
                />
                <Route
                    path="/regenerated/:id"
                    element={
                        isAuthenticated ? (
                            <RegeneratedDetails authData={authData} setAuthData={setAuthData}/>
                        ) : (
                            <Navigate to="/login" replace/>
                        )
                    }
                />
                <Route
                    path="/login"
                    element={
                        isAuthenticated ? (
                            <Navigate to="/" replace/>
                        ) : (
                            <Login setAuthData={setAuthData}/>
                        )
                    }
                />
                <Route
                    path="/login/google"
                    element={
                        isAuthenticated ? (
                            <Navigate to="/" replace/>
                        ) : (
                            <GoogleLogin setAuthData={setAuthData}/>
                        )
                    }
                />
                <Route
                    path="/publication/:id"
                    element={
                        isAuthenticated ? (
                            <PublicationDetails authData={authData}/>

                        ) : (
                            <Navigate to="/login" replace/>
                        )
                    }
                />
                <Route
                    path="/post/:id"
                    element={
                        isAuthenticated ? (
                            <PostDetails authData={authData}/>

                        ) : (
                            <Navigate to="/login" replace/>
                        )
                    }
                />
                <Route
                    path="/insta-story/:id"
                    element={
                        isAuthenticated ? (
                            <InstaStoryForm authData={authData}/>
                        ) : (
                            <Navigate to="/login" replace/>
                        )
                    }
                />
                <Route
                    path="/tik-tok/:id"
                    element={
                        isAuthenticated ? (
                            <TikTokForm authData={authData}/>
                        ) : (
                            <Navigate to="/login" replace/>
                        )
                    }
                />
                <Route
                    path="/insta-post/:id"
                    element={
                        isAuthenticated ? (
                            <PostForm authData={authData}/>
                        ) : (
                            <Navigate to="/login" replace/>
                        )
                    }
                />
                <Route
                    path="/generation"
                    element={
                        isAuthenticated ? (
                            <GenerationEntryPage authData={authData}/>
                        ) : (
                            <Navigate to="/login" replace/>
                        )
                    }
                />
                <Route
                    path="/generation/:id"
                    element={
                        isAuthenticated ? (
                            <GenerationChoosePage authData={authData}/>
                        ) : (
                            <Navigate to="/login" replace/>
                        )
                    }
                />
                <Route
                    path="/deleted"
                    element={
                        isAuthenticated ? (
                            <DeletedDashboard/>
                        ) : (
                            <Navigate to="/login" replace/>
                        )
                    }
                />
                <Route
                    path="/notifications"
                    element={
                        isAuthenticated ? (
                            <NotificationDashboard authData={authData}/>
                        ) : (
                            <Navigate to="/login" replace/>
                        )
                    }
                />
                <Route
                    path="/latest-posts"
                    element={
                        isAuthenticated ? (
                            <LatestDashboard authData={authData}/>
                        ) : (
                            <Navigate to="/login" replace/>
                        )
                    }
                />

            </Routes>
        </>
    );
}

export default App;
